/* eslint-disable react/destructuring-assignment,react/prop-types, no-underscore-dangle, react/jsx-one-expression-per-line, no-param-reassign */
import { navigate } from 'gatsby';
import React, { Component } from 'react';
import styled from '@emotion/styled';
import { usePagination, useSortBy, useTable, useFilters, useGlobalFilter } from 'react-table';
import {
  GlobalFilter,
  DefaultColumnFilter,
  SelectColumnFilter,
  SliderColumnFilter,
  NumberRangeColumnFilter,
  fuzzyTextFilterFn,
  getHiddenColumns, IndeterminateCheckbox
} from "../TableFilters/TableFilters";
import { withFirebase } from '../Firebase';
import { Box, BoxInline } from '../../style/basicStyle';
import toast from '../../utils/toast';
import feather from '../../utils/feather';
import { TableStyles, IconInline } from '../../style/tableStyling.js'

const ButtonIconInline = styled.div`
  display: inline;
  div {
    display: inline;
    div {
      margin-left: 5px;
      margin-right: 5px;
      display: inline;
    }
  }
`;


// using filter method from: https://codesandbox.io/s/github/tannerlinsley/react-table/tree/v7/examples/filtering?file=/src/App.js:8225-8550

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

function Table({ columns, data, initialState }) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state: { pageIndex, pageSize, globalFilter },
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    getToggleHideAllColumnsProps,
    allColumns
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: {
        hiddenColumns: getHiddenColumns(columns),
        pageIndex: 0,
        pageSize: 10,
        sortBy: [
          {
            id: 'lastSignInTimeSort',
            desc: true
          }
        ],
      }
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination,
  );


  // Render the UI for your table
  return (
    <>
      <div>
        <div>
          <IndeterminateCheckbox {...getToggleHideAllColumnsProps()} />
        </div>
        {/* Loop through columns data to create checkbox */}
        {allColumns.map((column) => (
          <div className="cb action" key={column.id}>
            <label>
              <input type="checkbox" {...column.getToggleHiddenProps()} />{" "}
              <span>{column.Header}</span>
            </label>
          </div>
        ))}
        <br />
      </div>
      <table {...getTableProps()}>
        <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>
                  <span  {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                    <span>{column.disableSortBy ? "" : column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                  </span>
                <div>{column.canFilter ? column.render('Filter') : null}</div>
              </th>
            ))}
          </tr>
        ))}
        <tr>
          <th
            colSpan={visibleColumns.length}
            style={{
              textAlign: 'left',
            }}
          >
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </th>
        </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })}
        </tbody>
      </table>
      <Box my={3}>
        <button type="button" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button type="button" onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button type="button" onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button type="button" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <span>
          | Go to page:{' '}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const pageGoto = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(pageGoto);
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[5, 10, 20].map((pageSizeNew) => (
            <option key={pageSizeNew} value={pageSizeNew}>
              Show {pageSizeNew}
            </option>
          ))}
        </select>
      </Box>
    </>
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter(row => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number'

const newPerson = (user, adminIdToken, currentUserRoles, signInWithCustomToken) => ({
  adminIdToken,
  currentUserRoles,
  signInWithCustomToken,
  username: user.username,
  displayName: user.displayName,
  email: user.email,
  uid: user.uid,
  clinics: user.roles && user.roles.CLINICS && 'yes',
  changePayment: user.roles && user.roles.CHANGEPAYMENT && 'yes',
  admin: user.roles && user.roles.ADMIN && 'yes',
  orders: user.roles && user.roles.ORDERS && 'yes',
  tournaments: user.roles && user.roles.TOURNAMENTS && 'yes',
  tuition: user.roles && user.roles.TUITION && 'yes',
  events: user.roles && user.roles.EVENTS && 'yes',
  tryouts: user.roles && user.roles.TRYOUTS && 'yes',
  roles: user.roles || '',
  emailVerified: user.emailVerified,
  lastSignInTime: user.lastSignInTime,
  creationTime: user.creationTime,
  lastSignInTimeSort: user.lastSignInTimeSort,
  creationTimeSort: user.creationTimeSort
});

function updateRole(values) {
  // eslint-disable-next-line react/prop-types
  fetch('/.netlify/functions/addRole', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(values)
  })
    .then((result) => {
      try {
        const resultJSON = result.json();
        resultJSON
          .then((r) => {
            if (result.status === 200) {
              console.log('UserList: Added role for user -- values', values);
              toast('Role updated!');
              window.location.reload();
            } else {
              console.log('UserList role request ERROR 1 - submitted values: ', values, ' | error is: ', r);
              toast(`Error 1 downloading list - response code: ${JSON.stringify(r)}`);
            }
          })
          .catch((error) => {
            console.log('UserList role request ERROR 2 - submitted values: ', values, ' | error is: ', error);
            toast(`Error 2 downloading list - error is: ${JSON.stringify(error.message)}`);
          });
      } catch (e) {
        console.log('UserList role request ERROR 22 - submitted values: ', values, ' | res is: ', result, ' | error is: ', e);
        toast(`Error 3 downloading list - error is: ${JSON.stringify(e.message)}`);
      }
    })
    .catch((error) => {
      console.log('UserList role request ERROR 3 - submitted values: ', values, ' | error is: ', error);
      toast(`Error 4 downloading list - error is: ${JSON.stringify(error.message)}`);
    });
}

function impersonateUser(signInWithCustomToken, values) {
  // eslint-disable-next-line react/prop-types
  fetch('/.netlify/functions/impersonateUser', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(values)
  })
    .then((result) => {
      try {
        const resultJSON = result.json();
        resultJSON
          .then((r) => {
            if (result.status === 200) {
              const authToken = r.message;
              toast('Impersonate User success');
              signInWithCustomToken(authToken);
              if (window) {
                navigate('/').then(() => console.log('Impersonation success'));
              }
            } else {
              console.log('impersonateUser ERROR 1 - submitted values: ', values, ' | error is: ', r);
              toast(`Error 1 impersonateUser - response code: ${JSON.stringify(r)}`);
            }
          })
          .catch((error) => {
            console.log('impersonateUser ERROR 2 - submitted values: ', values, ' | error is: ', error);
            toast(`Error 2 impersonateUser - error is: ${JSON.stringify(error.message)}`);
          });
      } catch (e) {
        console.log('impersonateUser ERROR 3 - submitted values: ', values, ' | res is: ', result, ' | error is: ', e);
        toast(`Error 3 impersonateUser - error is: ${JSON.stringify(e.message)}`);
      }
    })
    .catch((error) => {
      console.log('impersonateUserrequest ERROR 4 - submitted values: ', values, ' | error is: ', error);
      toast(`Error 4 impersonateUser - error is: ${JSON.stringify(error.message)}`);
    });
}

const columns = [
  {
    Header: 'Users',
    columns: [
      {
        Header: 'Username',
        accessor: 'username',
        Cell: ({ row: { original } }) => {
          const { uid } = original;
          const { adminIdToken } = original;
          const { currentUserRoles } = original;
          const { username } = original;
          const { signInWithCustomToken } = original;
          const values = { idToken: adminIdToken, targetUserId: uid };
          const action = currentUserRoles.ADMIN && (
            <ButtonIconInline>
              <BoxInline
                style={{ paddingLeft: '10px', display: 'inline' }}
                type="button"
                onClick={() => {
                  try {
                    console.log('button clicked, values: ', values);
                    toast(`Attempting to impersonate ${username}`);
                    impersonateUser(signInWithCustomToken, values);
                  } catch (e) {
                    console.log('UserList role - uncaught error - error is: ', e);
                    toast(`Error downloading list - error is: ${JSON.stringify(e.message)}`);
                  }
                }}
              >
                {feather('user', ['25', '25'])}
              </BoxInline>
              <BoxInline>{username}</BoxInline>
            </ButtonIconInline>
          );
          return action || username;
        }
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({ row: { original } }) => {
          const { email } = original;
          return (
            <a rel="noreferrer" target="_blank" href={`mailto:${email}`}>
              {email}
            </a>
          );
        }
      },
      {
        Header: 'Created At',
        accessor: 'creationTimeSort',
        disableFilters: true,
        Cell: ({ row: { original } }) => {
          let { creationTime } = original;
          let { creationTimeSort } = original;
          let sortResults;
          try {
            sortResults = creationTimeSort && (
              <span>{creationTime}</span>
            );
          } catch (e) {
            console.log('creationTimeSort CELL error: ', e);
          }
          return sortResults || null;
        }
      },
      {
        Header: 'Updated Date',
        accessor: 'lastSignInTimeSort',
        disableFilters: true,
        Cell: ({ row: { original } }) => {
          let { lastSignInTime } = original;
          let { lastSignInTimeSort } = original;
          let sortResults;
          try {
            sortResults = lastSignInTimeSort && (
              <span>{lastSignInTime}</span>
            );
          } catch (e) {
            console.log('lastSignInTimeSort CELL error: ', e);
          }
          return sortResults || null;
        },
        hideDefault: true
      },
      {
        Header: 'Admin',
        accessor: 'admin',
        disableFilters: true,
        Cell: ({ row: { original } }) => {
          const { uid } = original;
          const { adminIdToken } = original;
          const { admin } = original;
          const targetRole = 'ADMIN';
          const { currentUserRoles } = original;
          const checkPermission = currentUserRoles && (currentUserRoles[targetRole] || currentUserRoles.ADMIN);
          const styleOption = { paddingLeft: '10px' };
          if (!checkPermission) {
            styleOption.cursor = 'not-allowed';
            styleOption.color = 'gray';
          }
          const values = { idToken: adminIdToken, targetUserId: uid, targetRole };
          const action = (
            <IconInline>
              <Box
                style={styleOption}
                type="button"
                onClick={() => {
                  try {
                    updateRole(values);
                  } catch (e) {
                    console.log('UserList role - uncaught error - error is: ', e);
                    toast(`Error downloading list - error is: ${JSON.stringify(e.message)}`);
                  }
                }}
              >
                {feather((admin && 'check-square') || 'square', ['25', '25'])}
              </Box>
            </IconInline>
          );
          return action || null;
        },
        hideDefault: true
      },
      {
        Header: 'Tournaments',
        accessor: 'tournaments',
        disableFilters: true,
        Cell: ({ row: { original } }) => {
          const { uid } = original;
          const { adminIdToken } = original;
          const { tournaments } = original;
          const targetRole = 'TOURNAMENTS';
          const { currentUserRoles } = original;
          const checkPermission = currentUserRoles && (currentUserRoles[targetRole] || currentUserRoles.ADMIN);
          const styleOption = { paddingLeft: '10px' };
          if (!checkPermission) {
            styleOption.cursor = 'not-allowed';
            styleOption.color = 'gray';
          }
          const values = { idToken: adminIdToken, targetUserId: uid, targetRole };
          const action = (
            <IconInline>
              <Box
                style={{ paddingLeft: '10px' }}
                type="button"
                onClick={() => {
                  try {
                    updateRole(values);
                  } catch (e) {
                    console.log('UserList role - error updating order role - uncaught error - error is: ', e);
                    toast(`Error downloading list - error is: ${JSON.stringify(e.message)}`);
                  }
                }}
              >
                {feather((tournaments && 'check-square') || 'square', ['25', '25'])}
              </Box>
            </IconInline>
          );
          return action || null;
        },
        hideDefault: true
      },
      {
        Header: 'Tuition',
        accessor: 'tuition',
        disableFilters: true,
        Cell: ({ row: { original } }) => {
          const { uid } = original;
          const { adminIdToken } = original;
          const { tuition } = original;
          const targetRole = 'TUITION';
          const { currentUserRoles } = original;
          const checkPermission = currentUserRoles && (currentUserRoles[targetRole] || currentUserRoles.ADMIN);
          const styleOption = { paddingLeft: '10px' };
          if (!checkPermission) {
            styleOption.cursor = 'not-allowed';
            styleOption.color = 'gray';
          }
          const values = { idToken: adminIdToken, targetUserId: uid, targetRole };
          const action = (
            <IconInline>
              <Box
                style={styleOption}
                type="button"
                onClick={() => {
                  try {
                    updateRole(values);
                  } catch (e) {
                    console.log('UserList role - error updating order role - uncaught error - error is: ', e);
                    toast(`Error downloading list - error is: ${JSON.stringify(e.message)}`);
                  }
                }}
              >
                {feather((tuition && 'check-square') || 'square', ['25', '25'])}
              </Box>
            </IconInline>
          );
          return action || null;
        },
        hideDefault: true
      },
      {
        Header: 'Orders',
        accessor: 'orders',
        disableFilters: true,
        Cell: ({ row: { original } }) => {
          const { uid } = original;
          const { adminIdToken } = original;
          const { orders } = original;
          const targetRole = 'ORDERS';
          const { currentUserRoles } = original;
          const checkPermission = currentUserRoles && (currentUserRoles[targetRole] || currentUserRoles.ADMIN);
          const styleOption = { paddingLeft: '10px' };
          if (!checkPermission) {
            styleOption.cursor = 'not-allowed';
            styleOption.color = 'gray';
          }
          const values = { idToken: adminIdToken, targetUserId: uid, targetRole };
          const action = (
            <IconInline>
              <Box
                style={styleOption}
                type="button"
                onClick={() => {
                  try {
                    updateRole(values);
                  } catch (e) {
                    console.log('UserList role - error updating order role - uncaught error - error is: ', e);
                    toast(`Error downloading list - error is: ${JSON.stringify(e.message)}`);
                  }
                }}
              >
                {feather((orders && 'check-square') || 'square', ['25', '25'])}
              </Box>
            </IconInline>
          );
          return action || null;
        },
        hideDefault: true
      },
      {
        Header: 'Events',
        accessor: 'events',
        disableFilters: true,
        Cell: ({ row: { original } }) => {
          const { uid } = original;
          const { adminIdToken } = original;
          const { events } = original;
          const targetRole = 'EVENTS';
          const { currentUserRoles } = original;
          const checkPermission = currentUserRoles && (currentUserRoles[targetRole] || currentUserRoles.ADMIN);
          const styleOption = { paddingLeft: '10px' };
          if (!checkPermission) {
            styleOption.cursor = 'not-allowed';
            styleOption.color = 'gray';
          }
          const values = { idToken: adminIdToken, targetUserId: uid, targetRole };
          const action = (
            <IconInline>
              <Box
                style={styleOption}
                type="button"
                onClick={() => {
                  try {
                    updateRole(values);
                  } catch (e) {
                    console.log('UserList role error updating event role - uncaught error - error is: ', e);
                    toast(`Error updating value - error is: ${JSON.stringify(e.message)}`);
                  }
                }}
              >
                {feather((events && 'check-square') || 'square', ['25', '25'])}
              </Box>
            </IconInline>
          );
          return action || null;
        },
        hideDefault: true
      },
      {
        Header: 'Clinics',
        accessor: 'clinics',
        disableFilters: true,
        Cell: ({ row: { original } }) => {
          const { uid } = original;
          const { adminIdToken } = original;
          const { clinics } = original;
          const targetRole = 'CLINICS';
          const { currentUserRoles } = original;
          const checkPermission = currentUserRoles && (currentUserRoles[targetRole] || currentUserRoles.ADMIN);
          const styleOption = { paddingLeft: '10px' };
          if (!checkPermission) {
            styleOption.cursor = 'not-allowed';
            styleOption.color = 'gray';
          }
          const values = { idToken: adminIdToken, targetUserId: uid, targetRole };
          const action = (
            <IconInline>
              <Box
                style={styleOption}
                type="button"
                onClick={() => {
                  try {
                    updateRole(values);
                  } catch (e) {
                    console.log('UserList role error updating event role - uncaught error - error is: ', e);
                    toast(`Error updating value - error is: ${JSON.stringify(e.message)}`);
                  }
                }}
              >
                {feather((clinics && 'check-square') || 'square', ['25', '25'])}
              </Box>
            </IconInline>
          );
          return action || null;
        },
        hideDefault: true
      },
      {
        Header: 'Tryouts',
        accessor: 'tryouts',
        disableFilters: true,
        Cell: ({ row: { original } }) => {
          const { uid } = original;
          const { adminIdToken } = original;
          const { tryouts } = original;
          const targetRole = 'TRYOUTS';
          const { currentUserRoles } = original;
          const checkPermission = currentUserRoles && (currentUserRoles[targetRole] || currentUserRoles.ADMIN);
          const styleOption = { paddingLeft: '10px' };
          if (!checkPermission) {
            styleOption.cursor = 'not-allowed';
            styleOption.color = 'gray';
          }
          const values = { idToken: adminIdToken, targetUserId: uid, targetRole };
          const action = (
            <IconInline>
              <Box
                style={styleOption}
                type="button"
                onClick={() => {
                  try {
                    updateRole(values);
                  } catch (e) {
                    console.log('UserList role error updating tryout role - uncaught error - error is: ', e);
                    toast(`Error updating value - error is: ${JSON.stringify(e.message)}`);
                  }
                }}
              >
                {feather((tryouts && 'check-square') || 'square', ['25', '25'])}
              </Box>
            </IconInline>
          );
          return action || null;
        },
        hideDefault: true
      },
      {
        Header: 'Change Payment',
        accessor: 'changePayment',
        disableFilters: true,
        Cell: ({ row: { original } }) => {
          const { uid } = original;
          const { adminIdToken } = original;
          const { changePayment } = original;
          const targetRole = 'CHANGEPAYMENT';
          const { currentUserRoles } = original;
          const checkPermission = currentUserRoles && (currentUserRoles[targetRole] || currentUserRoles.ADMIN);
          const styleOption = { paddingLeft: '10px' };
          if (!checkPermission) {
            styleOption.cursor = 'not-allowed';
            styleOption.color = 'gray';
          }
          const values = { idToken: adminIdToken, targetUserId: uid, targetRole };
          const action = (
            <IconInline>
              <Box
                style={styleOption}
                type="button"
                onClick={() => {
                  try {
                    updateRole(values);
                  } catch (e) {
                    console.log('UserList role error updating event role - uncaught error - error is: ', e);
                    toast(`Error updating value - error is: ${JSON.stringify(e.message)}`);
                  }
                }}
              >
                {feather((changePayment && 'check-square') || 'square', ['25', '25'])}
              </Box>
            </IconInline>
          );
          return action || null;
        },
        hideDefault: true
      }
    ]
  }
];

class UserList extends Component {
  _initFirebase = false;

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: []
    };
  }

  componentDidMount() {
    this.firebaseInit();
  }

  componentDidUpdate() {
    this.firebaseInit();
  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      const self = this;
      this._initFirebase = true;

      this.setState({ loading: true });

      this.props.firebase.auth.onAuthStateChanged((authUser) => {
        if (authUser) {
          this.props.firebase.auth.currentUser
            // eslint-disable-next-line react/prop-types
            .getIdToken(/* forceRefresh */ true)
            .then((idToken) => {
              const values = { idToken };
              fetch('/.netlify/functions/getUsers', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(values)
              })
                .then((result) => {
                  try {
                    const resultJSON = result.json();
                    resultJSON
                      .then((r) => {
                        if (result.status === 200) {
                          self.setState({
                            // eslint-disable-next-line react/no-unused-state
                            users: r.message,
                            loading: false,
                            idToken
                          });
                        } else {
                          console.log('getUsers request ERROR 1 - submitted values: ', values, ' | error is: ', r);
                          toast(`Error 1 downloading list - response code: ${JSON.stringify(r)}`);
                        }
                      })
                      .catch((error) => {
                        console.log('getUsers request ERROR 2 - submitted values: ', values, ' | error is: ', error);
                        toast(`Error 2 downloading list - error is: ${JSON.stringify(error.message)}`);
                      });
                  } catch (e) {
                    console.log('getUsers request ERROR 22 - submitted values: ', values, ' | res is: ', result, ' | error is: ', e);
                    toast(`Error 3 downloading list - error is: ${JSON.stringify(e.message)}`);
                  }
                })
                .catch((error) => {
                  console.log('getUsers request ERROR 3 - submitted values: ', values, ' | error is: ', error);
                  toast(`Error 4 downloading list - error is: ${JSON.stringify(error.message)}`);
                });
            })
            .catch((error) => {
              console.log('getUsers request - getIdTokenError - error is: ', error);
            });
        }
      });
    }
  };

  formatTable() {
    const { state } = this;
    const signInWithCustomToken = this.props && this.props.firebase && this.props.firebase.doSignInWithCustomToken;
    const currentUserRoles = this.props && this.props.firebase && this.props.firebase.authUser && this.props.firebase.authUser.roles;
    const { users } = state;
    const { idToken } = state;
    const length = users && users.length;
    const formatTableLevel = (depth = 0) =>
      users.map((user) => ({
        ...newPerson(user, idToken, currentUserRoles, signInWithCustomToken),
        subRows: length[depth + 1] ? formatTableLevel(depth + 1) : undefined
      }));
    return formatTableLevel();
  }

  render() {
    const { loading } = this.state;

    return (
      <>
        <h2>Users</h2>
        {loading && <div>Loading ...</div>}
        <TableStyles>
          <Table
            columns={columns}
            data={this.formatTable()}
          />
        </TableStyles>
      </>
    );
  }
}

export default withFirebase(UserList);
